<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center>
      <v-flex xs9 sm6 md4 lg3>
        <v-card class="elevation-0 mt-12">
          <p class="display-1 text--primary text-center">Authorization</p>
          <v-form @submit.prevent="authorize" ref="authForm">
            <v-alert text color="red" type="error" v-if="errorMsg">
              {{ errorMsg }}
            </v-alert>
            <v-card-text>
              <v-text-field
                prepend-icon="mdi-account"
                name="login"
                label="Login"
                v-model="loginRequest.username"
                :rules="[requiredRule]"
                type="text"
              ></v-text-field>
              <v-text-field
                id="password"
                prepend-icon="mdi-lock"
                name="password"
                label="Password"
                v-model="loginRequest.password"
                :rules="[requiredRule]"
                type="password"
              ></v-text-field>
            </v-card-text>
            <v-card-actions class="d-flex justify-space-between">
              <v-btn
                type="submit"
                elevation="0"
                color="orange darken-3"
                rounded
                outlined
                class="pa-4"
              >
                Authorize
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { EventBus } from "@/utils";
import { AUTH_LOGIN } from "@/store/modules/auth/actions";
import { FAILED_AUTHENTICATION } from "@/utils/events";

export default {
  data() {
    return {
      loginRequest: {
        username: "",
        password: "",
      },
      errorMsg: "",
      requiredRule: (v) => !!v || "Required field",
    };
  },
  methods: {
    authorize() {
      const vm = this;
      if (!vm.$refs.authForm.validate()) {
        vm.errorMsg = "Please check all fields!";
      } else {
        let { loginRequest } = vm;
        vm.$store
          .dispatch(`auth/${AUTH_LOGIN}`, loginRequest)
          .then(
            () => {
              this.$store
                .dispatch("user/FETCH_PROFILE")
                .then(() => {
                  const fromRoute = localStorage.getItem("fromRoute");
                  const fromRouteQuery = localStorage.getItem("fromRouteQuery");
                  this.$router.push(
                    fromRoute
                      ? {
                          path: fromRoute,
                          query: JSON.parse(fromRouteQuery),
                        }
                      : { name: "SourceListPage" }
                  );
                })
                .catch();
            },
            (error) => {
              vm.errorMsg = error.details;
            }
          )
          .catch(() => {
            vm.errorMsg = "Unauthorized";
          });
      }
    },
  },
  mounted() {
    EventBus.$on(FAILED_AUTHENTICATION, (msg) => {
      this.errorMsg = msg;
    });
  },
  beforeDestroy() {
    EventBus.$off(FAILED_AUTHENTICATION);
  },
};
</script>
